import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMap,
  GeoJSON,
} from "react-leaflet";
import OpenrouteService from "openrouteservice-js";
import "./App.css";

const DirectionApi = new OpenrouteService.Directions({
  api_key: "5b3ce3597851110001cf6248dda36be72935439fb7fa880ec74f088f",
});

const MarkerLocation = () => {
  const [marker, setMarker] = useState([0, 0]);
  const map = useMap();

  navigator.geolocation.getCurrentPosition(
    (position) => {
      setMarker([position.coords.latitude, position.coords.longitude]);
      map.flyTo([position.coords.latitude, position.coords.longitude], 18);
    },
    (error) => {
      console.log(error);
    },
  );

  const watchPosition = useCallback(() => {
    navigator.geolocation.watchPosition(
      (position) => {
        setMarker([position.coords.latitude, position.coords.longitude]);
        console.log(position);
      },
      (error) => {
        console.log(error);
      },
    );
  }, []);

  useEffect(() => {
    watchPosition();
  }, [watchPosition]);

  return (
    <Marker position={marker}>
      <Popup>You are here. {JSON.stringify(marker)}</Popup>
    </Marker>
  );
};

const DestinationMarkerLocation = ({ startPoint }) => {
  const map = useMap();
  const [direction, setDirection] = useState(null);
  const destination = [-6.2900913, 106.8013104];

  const getDirection = async () => {
    const response = await DirectionApi.calculate({
      coordinates: [startPoint.reverse(), destination.reverse()],
      profile: "driving-car",
      avoidables: ["highways", "tollways", "ferries", "fords"],
      radiuses: [8000, 8000],
      geometry: true,
      format: "geojson",
    });
    console.log(response);
    setDirection(response);
    console.log(startPoint, destination)
    map.flyTo({lat: startPoint[1], lng: destination[0]}, 12)
  };

  if (direction) {
    return (
      <Fragment>
        <Marker position={[destination[0], destination[1]]}>
          <Popup>Your destination. {JSON.stringify(destination)}</Popup>
        </Marker>
        <GeoJSON data={direction} />
      </Fragment>
    );
  }

  return (
    <button
      onClick={getDirection}
      style={{ position: "absolute", top: 10, right: 10, zIndex: 400 }}
    >
      Click Here
    </button>
  );
};

const App2 = () => {
  const [center, setCenter] = useState([106, -6]);
  const [isLoaded, setIsLoaded] = useState(false);

  navigator.geolocation.getCurrentPosition(
    (position) => {
      setCenter([position.coords.latitude, position.coords.longitude]);
      setIsLoaded(true);
    },
    (error) => {
      console.log(error);
    },
  );

  if (isLoaded) {
    return (
      <MapContainer center={center} zoom={12} scrollWheelZoom={false}>
        <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
        <MarkerLocation />
        <DestinationMarkerLocation startPoint={center} />
        <button onClick={() => window.open('/#/', '_self')} style={{ position: "absolute", top: 40, right: 10, zIndex: 400 }}>Google Map</button>
      </MapContainer>
    );
  }

  return <div>Loading Maps..</div>;
};

export default App2;
