import React, { useEffect } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  DirectionsRenderer,
} from "@react-google-maps/api";
import MarkerDevices from "./components/MarkerDevices";

const containerStyle = {
  width: "100%",
  height: "100vh",
};

function App() {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCmCMDh28PE3V6vRv3iLkwn9BlxQAAowO8",
  });

  const [map, setMap] = React.useState(null);
  const [center, setCenter] = React.useState({ lat: 106, lng: -6 });
  const [directionsResponse, setDirectionsResponse] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    const ws = new WebSocket('wss://api.tracker.muchlisaffandi.my.id/ws');
    ws.onmessage = (message) => {
      console.log(message);
    };

    ws.onopen = () => {
      console.log('Connection opened');
      setInterval(() => {
        let payload = {
          kind: 'ping'
        }
        ws.send(JSON.stringify(payload));
      }, 3000);
    };

    ws.onclose = () => {
      console.log('Connection closed');
    };

    ws.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    navigator.geolocation.getCurrentPosition(
      (position) => {
        const bounds = new window.google.maps.LatLngBounds({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        map.fitBounds(bounds);
        setCenter({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        setMap(map);
      },
      (error) => {
        console.log(error);
      },
    );

    navigator.geolocation.watchPosition((position) => {
      console.log(position);
      if (ws.readyState === WebSocket.OPEN) {
        let payload = {
          kind: 'tracking.locate',
          data: {
            deviceID: "",
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          }
        }
        ws.send(JSON.stringify(payload));
      }
    });
  }, []);

  const calculateRoute = async () => {
    const directionsService = new window.google.maps.DirectionsService();
    // // const results = await directionsService.route({
    // //   origin: `${markerOrigin.lat},${markerOrigin.lng}`,
    // //   destination: "-6.2901044,106.8091049",
    // //   avoidFerries: true,
    // //   avoidTolls: true,
    // //   region: 'id',
    // //   travelMode: window.google.maps.TravelMode.DRIVING,
    // // });
    // setDirectionsResponse(results);
  };

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  useEffect(() => {}, [map]);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={1}
      onLoad={onLoad}
      onUnmount={onUnmount}
      options={{
        streetViewControl: false,
        mapTypeControl: false,
        fullscreenControl: false,
        zoomControl: false,
        rotateControl: false,
        scaleControl: false,
        panControl: false,
      }}
    >
      <MarkerDevices />
    </GoogleMap>
  ) : (
    <></>
  );
}

export default React.memo(App);
