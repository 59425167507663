import { InfoWindow, Marker } from "@react-google-maps/api";
import React, { useCallback, useEffect, useState } from "react";

const MarkerDevice = ({ device }) => {
  const [infoWindowOpen, setInfoWindowOpen] = useState(false);

  return (
    <Marker
      position={{ lat: device.Latitude, lng: device.Longitude }}
      onClick={() => setInfoWindowOpen(true)}
    >
      {infoWindowOpen && (
        <InfoWindow onCloseClick={() => setInfoWindowOpen(false)}>
          <div style={{maxWidth: 300}}>
            <h3>{device.id}</h3>
            <h4>{device.UserAgent}</h4>
          </div>
        </InfoWindow>
      )}
    </Marker>
  );
};

const MarkerDevices = () => {
  const [devices, setDevices] = useState([]);

  const fetchDevices = async () => {
    const sse = new EventSource(
      "https://api.tracker.muchlisaffandi.my.id/devices",
    );
    sse.onmessage = (event) => {
      if (event.data !== "{}") {
        let data = JSON.parse(event.data);
        let dataDevices = [];
        Object.keys(data).forEach((deviceID) => {
          dataDevices.push({
            ...data[deviceID],
            id: deviceID,
          });
        });
        setDevices(dataDevices);
        console.log(data);
      }
    };
  };

  useEffect(() => {
    fetchDevices();
  }, []);

  return devices.map((device, idx) => {
    return <MarkerDevice key={idx} device={device} />;
  });
};

export default MarkerDevices;
